import React from "react";
import "./AboutUs.css";
import { assets } from "../../assets/assets";

const AboutUs = () => {
  return (
    <div className="about_us">
      {/* <div className="about_img">
        <img src={assets.About} alt="" />
      </div> */}
      <div className="about_content">
        <h6>About Us</h6>
        <h4>We Provide Best Tour Packages In Your Budget</h4>
        <p>
          About Us Welcome to SAHYADRI TOURS & TRAVELS, where your journey
          begins. Strategically located opposite the Mini Civil Station in
          Kacherippadi, Manjeri, we have been a trusted name in the travel
          industry, offering a wide array of tour packages to both international
          and domestic destinations. Whether you're seeking the vibrant cultures
          of foreign lands or the serene beauty of local landscapes, our
          expertly curated tours are designed to provide unforgettable
          experiences. With our branches in Palakkad, Guruvayoor, Alappuzha, and
          Thiruvananthapuram, we are well-positioned to serve travelers across
          Kerala. Our dedicated team of travel professionals is committed to
          delivering personalized services, ensuring that every trip is tailored
          to meet your unique needs and preferences. At SAHYADRI TOURS &
          TRAVELS, we are passionate about making your travel dreams come true.
          Let us be your gateway to the world, where every journey is a story
          waiting to be told. For more information, please contact us at
          9846548150 or 9995389222.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
