import React, { useEffect, useState } from "react";
import "./Packages.css";
import { db } from "../../Firebase";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Packages = () => {
  const [entries, setEntries] = useState([]);

  const fetchEntries = async () => {
    try {
      const querySnapshot = await getDocs(
        query(collection(db, "PACKAGES"), where("STATUS", "==", "ACTIVE"))
      );

      const fetchedEntries = [];
      querySnapshot.forEach((doc) => {
        fetchedEntries.push({ id: doc.id, ...doc.data() });
      });
      fetchedEntries.sort(
        (a, b) => new Date(b.FROM_DATE) - new Date(a.FROM_DATE)
      );
      setEntries(fetchedEntries);
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  useEffect(() => {
    fetchEntries();
  }, []);

  const handleWhatsApp = (entries) => {
    const message =` Hello, Help me to know more about : ${entries.PACKAGE} ${entries.HEADING} `;
    window.location.href = `https://wa.me/9846548150?text=${encodeURIComponent(
      message
    )}`;
  };

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", options); // 'en-GB' formats as dd/mm/yyyy
  };

  return (
    <div className="packages">
      <h6>Packages</h6>
      <h4>Upcoming Tour Packages</h4>

      <div className="package_list">
        {entries.map((entry) => (
          <div className="item" key={entry.id}>
            <div className="item_img">
              {/* <img src={entry.IMAGE} alt="Package 1" /> */}
              <LazyLoadImage
                src={entry.IMAGE}
                effect="blur"
                placeholderSrc={entry.IMAGE}
                className="lazy-load-image"
              />
              <div className="overlay">
                <div>
                  <h6>{entry.HEADING}</h6>
                  <p>{entry.DESCRIPTION}</p>
                </div>
              </div>
            </div>
            <div className="item_content">
              <h6>{entry.HEADING}</h6>
              <div className="item_content_top">
                <p>{entry.DESCRIPTION}</p>
              </div>
              <span>{formatDate(entry.FROM_DATE)}</span>
              <span> to </span>
              <span>{formatDate(entry.TO_DATE)}</span>

              <button className="book" onClick={()=> handleWhatsApp(entry)}>
                Book now
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Packages;
