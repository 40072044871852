// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCzKjDTWuR6XGg9loZLFzNLLMIDV29f1GY",
  authDomain: "sahyadriwebsite.firebaseapp.com",
  projectId: "sahyadriwebsite",
  storageBucket: "sahyadriwebsite.appspot.com",
  messagingSenderId: "282475000711",
  appId: "1:282475000711:web:3fc268394681c710369922",
  measurementId: "G-F7QT2PP60L",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const storage = getStorage(app);
export { db, storage };
