import Logo from "./images/logo-dark-blue.png";
import About from "./images/carousel-1.webp";
import Packages1 from "./images/rajsthan.jpg";
import Packages2 from "./images/kashmir.jpg";
import Packages3 from "./images/kashi.jpg";
import Packages4 from "./images/hydrabd.jpg";
import Packages5 from "./images/lashaadweep.jpg";
import Spine from "./images/spineLogo.svg";
import Gallery1 from "./images/gallery1.webp";
import Gallery2 from "./images/gallery2.webp";
import Gallery3 from "./images/gallery3.webp";
import Gallery4 from "./images/galleery4.webp";
import gallery5 from "./images/galleery4.webp";
import gallery6 from "./images/gallery6.webp";
import gallery7 from "./images/gallery4.webp";
import gallery8 from "./images/gallery5.webp";
import gallery9 from "./images/gallery7.jpg";
import gallery10 from "./images/gallery9.jpg";
import Rajni from "./images/ranjini.webp";
import Sudhakaran from "./images/Sudhakaran.webp";

export const assets = {
  Logo,
  About,
  Packages1,
  Packages2,
  Packages3,
  Packages4,
  Packages5,
  Spine,
  Gallery1,
  Gallery2,
  Gallery3,
  Gallery4,
  gallery5,
  gallery6,
  gallery7,
  gallery8,
  gallery9,
  gallery10,
  Rajni,
  Sudhakaran,
};
