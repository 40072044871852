import React from "react";
import "./Spotlight.css";
import { FaWhatsapp } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";

const Spotlight = () => {
  const handleCall = () => {
    window.location.href = `tel:${+919846548150}`;
  };

  const handleWhatsApp = () => {
    const message = "Hello, I need support";
    window.location.href = `https://wa.me/${9846548150}?text=${encodeURIComponent(
      message
    )}`;
  };
  return (
    <>
      <div className="spotlight">
        <div className="content">
          <h5>Explore the World: Your Next Adventure Awaits!</h5>
        </div>
      </div>

      <div className="socila_top">
        <div className="whtsp" onClick={handleWhatsApp}>
          <FaWhatsapp />
        </div>
        <div className="phn" onClick={handleCall}>
          <FaPhone />
        </div>
      </div>
    </>
  );
};

export default Spotlight;
