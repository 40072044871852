import React, { useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import { IoClose, IoMenu } from "react-icons/io5";

import { assets } from "../../assets/assets";
import "./Header.css";

const Header = () => {
  const [activeSection, setActiveSection] = useState("home");
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleSetActive = (to) => {
    setActiveSection(to);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <header>
      <h1>
        <img src={assets.Logo} alt="" className="logoo" />
      </h1>
      <ul className={isMobileMenuOpen ? "mobile-menu" : ""}>
        <li>
          <ScrollLink
            to="home"
            spy={true}
            smooth={true}
            duration={500}
            onSetActive={handleSetActive}
            className={activeSection === "home" ? "active" : ""}
            onClick={closeMobileMenu}
          >
            Home
          </ScrollLink>
        </li>
        <li>
          <ScrollLink
            to="about"
            spy={true}
            smooth={true}
            duration={500}
            onSetActive={handleSetActive}
            className={activeSection === "about" ? "active" : ""}
            onClick={closeMobileMenu}
          >
            About
          </ScrollLink>
        </li>
        {/* <li>
          <ScrollLink
            to="services"
            spy={true}
            smooth={true}
            duration={500}
            onSetActive={handleSetActive}
            className={activeSection === "services" ? "active" : ""}
            onClick={closeMobileMenu}
          >
            Services
          </ScrollLink>
        </li> */}
        <li>
          <ScrollLink
            to="packages"
            spy={true}
            smooth={true}
            duration={500}
            onSetActive={handleSetActive}
            className={activeSection === "packages" ? "active" : ""}
            onClick={closeMobileMenu}
          >
            Packages
          </ScrollLink>
        </li>
        <li>
          <ScrollLink
            to="gallery"
            spy={true}
            smooth={true}
            duration={500}
            onSetActive={handleSetActive}
            className={activeSection === "gallery" ? "active" : ""}
            onClick={closeMobileMenu}
          >
            Gallery
          </ScrollLink>
        </li>
        <li>
          <ScrollLink
            to="OurTourCoordinators"
            spy={true}
            smooth={true}
            duration={500}
            onSetActive={handleSetActive}
            className={activeSection === "OurTourCoordinators" ? "active" : ""}
            onClick={closeMobileMenu}
          >
            Tour Co-ordinators
          </ScrollLink>
        </li>
        {/* <li>
          <ScrollLink
            to="contact"
            spy={true}
            smooth={true}
            duration={500}
            onSetActive={handleSetActive}
            className={activeSection === "footer" ? "active" : ""}
            onClick={closeMobileMenu}
          >
            Contact
          </ScrollLink>
        </li> */}
      </ul>
      {isMobileMenuOpen ? (
        <IoClose onClick={toggleMobileMenu} className="menu-icon" />
      ) : (
        <IoMenu onClick={toggleMobileMenu} className="menu-icon" />
      )}
    </header>
  );
};

export default Header;
