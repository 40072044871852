// import React from "react";
// import "./Gallery.css";
// import { assets } from "../../assets/assets";

// const Gallery = () => {
//   return (
//     <div className="gallery">
//       <h6>Gallery</h6>

//       <h4>Captured Moments</h4>

//       <div className="gallery_list">
//         <div className="gallery_img">
//           <img src={assets.Gallery1} alt="" />
//         </div>
//         <div className="gallery_img">
//           <img src={assets.Gallery2} alt="" />
//         </div>
//         <div className="gallery_img">
//           <img src={assets.Gallery3} alt="" />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Gallery;

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Gallery.css";
import { assets } from "../../assets/assets";

const Gallery = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="gallery">
      <h6>Gallery</h6>
      <h4>Captured Moments</h4>
      <Slider {...settings} className="gallery_slider">
        <div className="gallery_img">
          <img src={assets.Gallery1} alt="" />
        </div>
        <div className="gallery_img">
          <img src={assets.Gallery2} alt="" />
        </div>
        <div className="gallery_img">
          <img src={assets.Gallery3} alt="" />
        </div>
        <div className="gallery_img">
          <img src={assets.gallery5} alt="" />
        </div>
        <div className="gallery_img">
          <img src={assets.gallery6} alt="" />
        </div>
        <div className="gallery_img">
          <img src={assets.gallery7} alt="" />
        </div>
        <div className="gallery_img">
          <img src={assets.gallery8} alt="" />
        </div>
        <div className="gallery_img">
          <img src={assets.gallery9} alt="" />
        </div>
        <div className="gallery_img">
          <img src={assets.gallery10} alt="" />
        </div>
      </Slider>
    </div>
  );
};

export default Gallery;
