import React, { useRef } from "react";
import Header from "../components/Header/Header";
import Spotlight from "../components/Spotlight/Spotlight";
import AboutUs from "../components/AboutUs/AboutUs";
import Packages from "../components/Packages/Packages";
import Gallery from "../components/Gallery/Gallery";
import Footer from "../components/Footer/Footer";
import OurGuides from "../components/OurGuides/OurGuides";

const Home = () => {
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const galleryRef = useRef(null);
  const packagesRef = useRef(null);
  const contactRef = useRef(null);

  // Define a scroll function
  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div>
      <Header />
      <div id="home" ref={homeRef}>
        <Spotlight />
      </div>
      <div id="about" ref={aboutRef}>
        <AboutUs />
      </div>
      <div id="packages" ref={packagesRef}>
        <Packages />
      </div>
      <div id="gallery" ref={galleryRef}>
        <Gallery />
      </div>
      <div id="OurTourCoordinators" ref={contactRef}>
        <OurGuides />
      </div>
      <Footer
        scrollToSection={scrollToSection}
        refs={{ homeRef, aboutRef, galleryRef, packagesRef, contactRef }}
      />
    </div>
  );
};

export default Home;
