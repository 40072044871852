import React from "react";
import "./OurGuides.css";
import { assets } from "../../assets/assets";
import { LazyLoadImage } from "react-lazy-load-image-component";

const OurGuides = () => {
  return (
    <div className="ourGuides">
      <h6>Tour Coordinators</h6>

      <h4>Our Tour Co-ordinators</h4>

      <div className="guide_container">
        <div className="guides">
          <div className="guide_img">
            {/* <img src={assets.guid_5} alt="" /> */}
            <LazyLoadImage
              src={assets.Sudhakaran}
              effect="blur"
              placeholderSrc={assets.Sudhakaran}
              className="lazy-load-image"
            />
          </div>
          <div className="content_guide">
            <span>Sudhakaran</span>
            {/* <p>Designation</p> */}
          </div>
        </div>

        <div className="guides">
          <div className="guide_img">
            {/* <img src={assets.guid_2} alt="" /> */}
            <LazyLoadImage
              src={assets.Rajni}
              effect="blur"
              placeholderSrc={assets.Rajni}
              className="lazy-load-image"
            />
          </div>
          <div className="content_guide">
            <span>Ranjini PS</span>
            {/* <p>Designation</p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurGuides;
