// import React, { useEffect } from "react";
// import "./Footer.css";
// import { FaInstagram } from "react-icons/fa";
// import { FaFacebook } from "react-icons/fa";
// import { FaYoutube } from "react-icons/fa";
// import { assets } from "../../assets/assets";
// import { RiArrowRightSFill } from "react-icons/ri";

// const Footer = () => {
//   return (
//     <div className="footer">
//       <footer>
//         <ul className="main_social">
//           <img src={assets.Logo} alt="" />
//           <li>Crafting unforgettable journeys, one dream at a time.</li>
//           <ul className="social">
//             <li className="insta">
//               <a
//                 href="https://www.instagram.com/sahyadritourstravels?igsh=MXM4NDMzeGt0b2xpYw=="
//                 target="_blank"
//                 rel="noopener noreferrer"
//               >
//                 <FaInstagram />
//               </a>
//             </li>
//             <li className="facebook">
//               <a
//                 href="https://www.facebook.com/profile.php?id=100089770514215&mibextid=kFxxJD"
//                 target="_blank"
//                 rel="noopener noreferrer"
//               >
//                 <FaFacebook />
//               </a>
//             </li>
//             <li className="youtube">
//               <FaYoutube />
//             </li>
//           </ul>
//         </ul>
//         <ul className="our_servi">
//           <h6> Our Services</h6>
//           <li>
//             <RiArrowRightSFill />
//             Home
//           </li>
//           <li>
//             <RiArrowRightSFill />
//             About
//           </li>
//           <li>
//             <RiArrowRightSFill />
//             Services
//           </li>
//           <li>
//             <RiArrowRightSFill />
//             Packages
//           </li>
//           <li>
//             <RiArrowRightSFill />
//             Contact
//           </li>
//         </ul>

//         <ul className="branch">
//           <h6>Our Branches</h6>
//           <li> Palakkad </li>
//           <li>Guruvayoor</li>
//           <li>Alappuzha</li>
//           <li>Thiruvananthapuram</li>
//         </ul>

//         <ul className="contact_main">
//           <h6>Contact Us</h6>
//           <li>
//             Opp. Mini Civil Station, Kacherippadi, Manjeri, Malappuram - 676121
//           </li>
//           <li>
//             <span>Phone:</span>
//             <a href="tel:+919846548150">+91 9846548150</a>,
//             <a href="tel:+919995389222">+91 9995389222</a>
//           </li>

//           <li>
//             <span>Email:</span>
//             <a href="mailto:sahyadritoursmji@gmail.com">
//               sahyadritoursmji@gmail.com
//             </a>
//           </li>
//         </ul>
//       </footer>
//       <div className="footer_bottom">
//         <p>© SAHYADRI TOURS & TRAVELS 2024 . All Rights Reserved.</p>
//         <p>
//           <span>Developed By</span> <img src={assets.Spine} alt="" />
//         </p>
//       </div>
//     </div>
//   );
// };

// export default Footer;

import React from "react";
import "./Footer.css";
import { FaInstagram, FaFacebook, FaYoutube } from "react-icons/fa";
import { assets } from "../../assets/assets";
import { RiArrowRightSFill } from "react-icons/ri";

const Footer = ({ scrollToSection, refs }) => {
  return (
    <div className="footer">
      <footer>
        <ul className="main_social">
          <img src={assets.Logo} alt="" />
          <li>Crafting unforgettable journeys, one dream at a time.</li>
          <ul className="social">
            <li className="insta">
              <a
                href="https://www.instagram.com/sahyadritourstravels?igsh=MXM4NDMzeGt0b2xpYw=="
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram />
              </a>
            </li>
            <li className="facebook">
              <a
                href="https://www.facebook.com/profile.php?id=100089770514215&mibextid=kFxxJD"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook />
              </a>
            </li>
            <li className="youtube">
              <FaYoutube />
            </li>
          </ul>
        </ul>
        <ul className="our_servi">
          <h6> Our Services</h6>
          <li onClick={() => scrollToSection(refs.homeRef)}>
            <RiArrowRightSFill />
            Home
          </li>
          <li onClick={() => scrollToSection(refs.aboutRef)}>
            <RiArrowRightSFill />
            About
          </li>
          <li onClick={() => scrollToSection(refs.galleryRef)}>
            <RiArrowRightSFill />
            Gallery
          </li>
          <li onClick={() => scrollToSection(refs.packagesRef)}>
            <RiArrowRightSFill />
            Packages
          </li>
          <li onClick={() => scrollToSection(refs.contactRef)}>
            <RiArrowRightSFill />
            Contact
          </li>
        </ul>

        <ul className="branch">
          <h6>Our Branches</h6>
          <li>Palakkad</li>
          <li>Guruvayoor</li>
          <li>Alappuzha</li>
          <li>Thiruvananthapuram</li>
        </ul>

        <ul className="contact_main">
          <h6>Contact Us</h6>
          <li>
            Opp. Mini Civil Station, Kacherippadi, Manjeri, Malappuram - 676121
          </li>
          <li>
            <span>Phone:</span>
            <a href="tel:+919846548150">+91 9846548150</a>,
            <a href="tel:+919995389222">+91 9995389222</a>
          </li>

          <li>
            <span>Email:</span>
            <a href="mailto:sahyadritoursmji@gmail.com">
              sahyadritoursmji@gmail.com
            </a>
          </li>
        </ul>
      </footer>
      <div className="footer_bottom">
        <p>© SAHYADRI TOURS & TRAVELS 2024 . All Rights Reserved.</p>
        <p>
          <span>Developed By</span>
          {/* <img src={assets.Spine} alt="" /> */}
          <a
            href="https://www.spinecodes.com/"
            style={{ color: "white", textDecoration: "none" }}
          >
            Spinecodes
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
